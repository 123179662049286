
import { defineComponent, ref, reactive } from "vue";

import { useRoute, useRouter } from "vue-router";
import { SubFormItemGroup } from "@/components/check/SubFormItemGroup.vue";
import { eventsService } from "@/service";
import { v4 as uuidv4 } from "uuid";
import { useValidator } from "@/composables";
import { Toast } from "vant";
import _ from "lodash";
import { useTitle } from "@vueuse/core";
export default defineComponent({
  name: "EventsStart",
  setup() {
    const title = useTitle();
    const show = ref(true);
    const subFormItemGroupRef = ref<SubFormItemGroup | null>(null);
    const route = useRoute();
    const router = useRouter();
    const detail = reactive<any>({});
    const tempInfo = ref<Approval.SubComponents | null>(null);
    const originWorkbookJson = ref<Approval.SubComponents | null>(null);
    // 提交
    const loading = ref(false);

    const departmentInfo = reactive<any>({
      id: "",
      show: false,
      list: [],
      confirm: () => {}
    });

    const getEventTypeDetail = async () => {
      let data: any = {};

      if (route.query.id) {
        const { data: res } = await eventsService.eventDetail(route.query.id as string);
        data = res;
        data.data.workbook_json = data.data.event_json;
      } else {
        const { data: res } = await eventsService.eventTypeDetail(route.params.id as string);
        data = res;
      }
      Object.assign(detail, data.data);

      if (detail.handler_users_type === "2") {
        departmentInfo.list = detail.handler_users.department_arr;
      }

      title.value = data.data.name || "";

      (data.data.workbook_json as Approval.SubComponents).forEach(item => {
        item.id = uuidv4();
      });
      originWorkbookJson.value = data.data.workbook_json;

      tempInfo.value = _.cloneDeep(data.data.workbook_json);
      tempInfo.value?.forEach((item: any) => {
        item._type = item.type;
        item.type = item.check_type;
        if (item.photo_num) {
          item.num_max = item.photo_num;
        }
        if (item.digit_num) {
          item.decimal_show = 1;
        }
        if (item.select_type) {
          // 1 单选 2多选
          item.is_radio = item.select_type == 1 ? 1 : 0;
        }
      });
    };

    getEventTypeDetail();

    const submit = () => {
      if (loading.value) return;
      subFormItemGroupRef.value?.submit(async value => {
        if (tempInfo.value) {
          const find = useValidator(tempInfo.value, value);
          if (find) {
            Toast.fail(find.message);
            return;
          }
        }

        if (detail.handler_users_type === "2") {
          departmentInfo.show = true;
          await new Promise(resolve => {
            departmentInfo.confirm = (data: any) => {
              departmentInfo.id = data.id;
              resolve(data);
            };
          });
        }

        originWorkbookJson.value?.forEach(item => {
          ["pic_ids"].forEach(key => {
            if (key in item) {
              item[key] = value[item.id];
            }
          });
          item["result"] = value[item.id];
        });

        try {
          const id = route.params.id as string;
          let event_type = id;
          if (id === "52") {
            event_type = originWorkbookJson.value?.[0].result as string;
          }
          await eventsService.addNewEvent({
            event_type,
            event_json: JSON.stringify(originWorkbookJson.value),
            department_id: departmentInfo.id
          });
          Toast.success("提交成功");
          router.go(-1);
          // eslint-disable-next-line no-undef
          wx.miniProgram.navigateBack(1);
        } catch (error) {}
      });
    };

    return {
      submit,
      tempInfo,
      subFormItemGroupRef,
      loading,
      show,
      departmentInfo
    };
  }
});
